* {
    // height: 100vh;

    .sections {
        max-width: 1920px;
        height: calc(100vh - 5rem);
        background-color: #FFF8F8;
        position: relative;
        top: 5rem;
        scroll-behavior: smooth;
        // scroll-snap-type: y mandatory;
        scrollbar-width: none;
        &::-webkit-scrollbar {
            display: none;
        }
    }

        // > * {
        //     // width: 100vw;
        //     scroll-snap-align: start;
        //     // height: calc(100vh - 5rem);
        // }

}