@import "../../global.scss";

.projects {
    height: auto;
    background-color: $navText;
    overflow: auto;


    h1 {
        font-family: 'Work Sans', sans-serif;
        text-align: center;
        padding-bottom: 2rem;
        margin-top: 3rem;
        color: $mainColor;
        font-weight: 400;
        font-style: italic;
        font-size: 3rem;
        text-shadow: $textHighlight 1px 1px;
    }
    h2 {
        font-family: 'Bree Serif', serif;

    }
    .project-images {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
    }
        .project-images > a {
            padding: 2rem;
            width: 35rem;
        }
        img {
            width: 100%;
            margin: 1rem;
            box-shadow: 0px 20px 40px rgba(0,0,0,0.4);
            transition: transform .2s;
            border-radius: 10px;
        }
        img:hover {
            cursor: pointer;
            transform: scale(1.1);
        }
        
}
