@import "../../global.scss";

@media (min-width: 810px) {
    .intro {
        font-family: 'Bree Serif', serif;
        height: 70rem;
        width: 100%;
        font-size: 5rem;
        // margin-left: 4rem;
        padding: 3rem;
        color: $navText;
        text-shadow: #822250 1px 1px;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        background-color: rgb(73, 25, 59);
        font-family: 'Bree Serif', serif;
        overflow: hidden;

        .container {
            margin-top: 4rem;
            font-family: 'Bree Serif', serif;
            font-size: 3rem;
            height: 100vh;

        }
            h1 {
                width: 80%;
                margin-left: 3rem;
                padding-bottom: -5rem;
                // height: 30rem;
            }
            .highlight {
                color:#DEABC3;
            }
            p {
                width: 80%;
                font-family: 'Work Sans', sans-serif;
                padding-bottom: -5rem;
                margin: 1rem;
                font-size: 2rem;
            }

        .img {
            width: 100%;
        }
    }
}

@media (max-width: 809px) {
    .intro {
        font-family: 'Bree Serif', serif;
        // height: 70rem;
        width: 100%;
        font-size: 1.5rem;
        padding: 1rem;
        color: $navText;
        text-shadow: #822250 1px 1px;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        background-color: rgb(73, 25, 59);
        font-family: 'Bree Serif', serif;
        overflow: hidden;
    }
    .container {
        margin-top: .5rem;
        height: auto;

    }
        h1 {
            width: 80%;
            margin-left: 1rem;
            padding-bottom: -5rem;
            text-align: center;
        }
        .highlight {
            color:$textHighlight;
        }
        p {
            width: 80%;
            font-family: 'Work Sans', sans-serif;
            padding-bottom: -5rem;
            margin: 1rem;
            font-size: 1.2rem;
        }

    .img {
        width: 100%;
    }
}













    // SCROLL INDICATOR 
    // .scroll-down {
    //     position: absolute;
    //     bottom: 5rem;
    //     left: 0%;
    //     transform: translateX(-50%);
    //     transform: translateY(-60%);
    //     display: flex;
    //     flex-direction: column;
    //     align-items: center;
    // }
    // .mouse {
    //     width: 2.5rem;
    //     height: 5rem;
    //     border: 2px solid #ffffff;
    //     border-radius: 2rem;
    //     margin-bottom: .5rem;
    //     display: flex;
    //     margin: auto;
    //     padding-top: 1rem;
    // }
    // .mouse span {
    //     width: 1rem;
    //     height: 1rem;
    //     background-color: #ffffff;
    //     display: block;
    //     border-radius: 50%;
    //     margin: auto;
    //     animation: move-wheel 1s linear infinite;
    // }
    // .arrow span {
    //     display: block;
    //     width: 1rem;
    //     height: 1rem;
    //     border: 2px solid transparent;
    //     border-right-color:#ffffff;
    //     border-bottom-color: #ffffff;
    //     transform: rotate(45deg);
    //     animation: arrow-down .5s alternate infinite;
    //     margin:auto;
    // }
    // @keyframes move-wheel {
    //     0% {
    //         opacity: 0;
    //         transform: translateY(-1rem); 
    //     }
    //     100% {
    //         opacity: 1;
    //         transform: translateY(1rem); 
    //     }
    // }
    // @keyframes arrow-down {
    //     0% {
    //         opacity: 0;
    //     }
    //     25% {
    //         opacity: .25;
    //     }
    //     50% {
    //         opacity: .5;
    //     }
    //     75% {
    //         opacity: .75;
    //     }
    //     100% {
    //         opacity: 1;
    //     }
    // }
