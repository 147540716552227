@import "../../global.scss";

.resume {
    height: auto;
    margin: auto;
    display: flex;
    flex-direction: column;

    h1 {
        font-family: 'Work Sans', sans-serif;
        text-align: center;
        padding-bottom: 2rem;
        margin-top: 3rem;
        color: $mainColor;
        font-weight: 400;
        font-style: italic;
        font-size: 3rem;
        text-shadow: $textHighlight 1px 1px;
    }
    h3 {
        font-family: 'Work Sans', sans-serif;
        text-align: center;
        font-weight: 500;
    }
    a {
        color: $mainColor;
    }
    img {
        width: 70%;
    }




}